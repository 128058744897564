export const MATCH_DURATION_FOOTBALL = 90;
export const MATCH_DURATION_FUTSAL = 40;
export const MATCH_MAX_PLAYERS_FOOTBALL = 11;
export const MATCH_MAX_PLAYERS_FUTSAL = 5;
export const MAX_INTEGER_VALUE = Number.MAX_SAFE_INTEGER;
export const MIN_INTEGER_VALUE = Number.MIN_SAFE_INTEGER;

export const MAX_INTEGER_AMOUNT_VALUE = 90071992547409;

/* Absolute or relative path to a page where a newly
matured adult can be migrated into an autonomous profile.
 */
export const MATURED_PERSON_RECOVERY_URL_PATH = '/person-recovery';

export const ANONYMOUS_PAYMENT_URL_PATH = '/anonymous-payment';

/**
 * Device is considered a phone if it's width is less than this value (in pixels).
 */
export const MOBILE_PHONE_MAX_WIDTH = 730;
